const Env = {
  server: "", // Used in CI to call relative path for server
  version: "20241118-093524",
  //If domain is inferenceweb.bookcourt.praxiel.fr then server is "https://api.infew.bookcourt.praxiel.fr"
  ...(document.location.hostname.includes("inferenceweb.bookcourt.praxiel.fr")
    ? {
        server: "https://api.infew.bookcourt.praxiel.fr",
      }
    : {}),
    isBeta:false,
    defaultExternalDB: true,
    maxInactiv: 45,
    lastRefresh: 0,
    refreshFreq: 30,
    debugAlert: false,
};

export default Env;

